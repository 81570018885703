<template>
  <van-cell-group>
    <template v-for="(item, index) in formItems">
      <van-field
        v-if="item.type === 'text'"
        :key="index"
        v-model="form[item.code]"
        :label="item.name"
        placeholder="请输入"
      />
      <van-field
        v-if="item.type === 'textarea'"
        :key="index"
        rows="2"
        autosize
        type="textarea"
        v-model="form[item.code]"
        :label="item.name"
        maxlength="50"
        placeholder="请输入"
      />
      <VantFieldDate
        v-if="item.type === 'date'"
        v-model="form[item.code]"
        :label="item.name"
        type="datetime"
        :key="index"
        placeholder="请选择"
        border
      />

      <VantFieldSelect
        :key="index"
        v-if="item.type === 'select'"
        v-model="form[item.code]"
        :label="item.name"
        :columns="item.optionArr"
        placeholder="请选择"
        is-link
        border
      />

      <van-field
        class="form-line"
        :label="item.name"
        :key="index"
        v-if="item.type === 'radio'"
      >
        <template #input>
          <van-radio-group v-model="form[item.code]">
            <van-cell v-for="val in item.optionArr" :key="val">
              <van-radio :name="val">{{ val }}</van-radio>
            </van-cell>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        class="form-line"
        :label="item.name"
        :key="index"
        v-if="item.type === 'checkbox'"
      >
        <template #input>
          <van-checkbox-group v-model="form[item.code]">
            <van-cell v-for="val in item.optionArr" :key="val">
              <van-checkbox :name="val" shape="square">{{ val }}</van-checkbox>
            </van-cell>
          </van-checkbox-group>
        </template>
      </van-field>
    </template>
  </van-cell-group>
</template>
<script>
import VantFieldDate from '@/components/form/VantFieldDate'
import VantFieldSelect from '@/components/form/VantFieldSelect'
export default {
  props: {
    form_id: {
      type: [Number, String],
      default: 0
    },
    formItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    VantFieldDate,
    VantFieldSelect
  }
}
</script>
<style lang="scss" scoped>
.form-line {
  display: block;
  ::v-deep .van-field__label {
    width: 100% !important;
  }
  ::v-deep .van-checkbox-group,
  ::v-deep .van-radio-group {
    width: 100%;
  }
  // ::v-deep .van-cell{
  //   padding-right: 0;
  //   padding-left: 0;
  // }
}
</style>