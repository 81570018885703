<template>
  <van-popup v-model="visible" position="bottom" :style="{ height: '100%' }">
    <!-- <pre>
    {{ formItems }}
  </pre
    > -->
    <div style="margin-bottom: 45px">
      <FormList :formItems="formItems" :form="form" />
    </div>
    <van-tabbar>
      <van-button @click="onSubmit" type="info" block>立即参与活动</van-button>
    </van-tabbar>
  </van-popup>
</template>
<script>
import FormList from '@/components/formList/index'
export default {
  props: {
    form_id: {
      type: [Number, String],
      default: 0
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    FormList
  },
  data () {
    return {
      visible: false,
      formItems: []
    }
  },
  methods: {
    show () {
      this.visible = true
      this.getFormItems()
    },
    hide(){
      this.visible = false
    },
    getFormItems () {
      this.$axios.post('/wxc/form/detail', {
        form_id: this.form_id,
        status: 1
      }).then(res => {
        if (res.code === 200) {
          res.data.item_list.map((item, index) => {
            let val = item.default_val || ''

            this.$set(this.form, item.code, val);

            this.form[item.code] = item.default_val || ''
            res.data.item_list[index].optionArr = item.option_val.split('|')

            if (item.type === 'checkbox') {
              this.form[item.code] = []
            }

            // if(item.type === 'checkbox' || item.type === 'radio'){
            //   this.form[item.code] = item.option_val.split('|')[0]
            // }

          })
          this.formItems = res.data.item_list
          this.$forceUpdate()
        }
      })
    },
    onSubmit () {
      this.$parent.onSubmit(false)
    }
  }
}
</script>
<style lang="scss" scoped>
.form-line {
  display: block;
  ::v-deep .van-field__label {
    width: 100% !important;
  }
  ::v-deep .van-checkbox-group,
  ::v-deep .van-radio-group {
    width: 100%;
  }
  // ::v-deep .van-cell{
  //   padding-right: 0;
  //   padding-left: 0;
  // }
}
</style>