<template>
  <div class="app-container">
    <div class="cover-img-box">
      <van-image :src="dataList.cover_img" class="cover-img"> </van-image>
      <div class="join-count">
        <span class="num">{{ dataList.join_count }}</span
        >人报名
      </div>
    </div>
    <van-cell-group class="act-info">
      <div class="title">{{ dataList.title }}</div>
      <div class="desc gray font12">{{ dataList.desc }}</div>
    </van-cell-group>

    <van-cell-group>
      <van-cell
        class="price"
        :title="'¥' + (goodsDetail.sale_price / 100 || '0.00')"
      >
        <div class="timer-box font12" v-if="timerStatus === 'doing'">
          <van-icon class="ico" name="clock-o" /> 剩余
          <span class="timer">{{ timerTxt }}</span>
        </div>
      </van-cell>
    </van-cell-group>
    <van-cell-group title=" " v-if="dataList.is_card_visible === 1">
      <van-sticky>
        <Card />
      </van-sticky>
    </van-cell-group>
    <van-cell-group border class="gs" title=" ">
      <div class="gs-item">
        <van-icon class="icon" name="passed" />
        <span class="txt">限时限量</span>
      </div>
      <div class="gs-item">
        <van-icon class="icon" name="passed" />
        <span class="txt">多重豪礼</span>
      </div>
      <div class="gs-item">
        <van-icon class="icon" name="passed" />
        <span class="txt">旧车收购</span>
      </div>
      <div class="gs-item">
        <van-icon class="icon" name="passed" />
        <span class="txt">价格透明</span>
      </div>
    </van-cell-group>

    <van-cell-group title=" " border>
      <van-cell class="title-h2"> 活动详情 </van-cell>
      <div class="rich_media_content" v-html="dataList.content"></div>
    </van-cell-group>

    <van-cell-group title=" " border>
      <van-cell class="title-h2">
        参与人数（{{ dataList.join_count }}人）
      </van-cell>
      <div class="join-list">
        <div class="no-data font12" v-if="!dataList.join_list.length">
          没有参与用户
        </div>
        <div
          v-else
          class="list-item"
          v-for="(item, index) in dataList.join_list"
          :key="index"
        >
          <van-image
            :src="item.wechat_avatar"
            round
            width="28px"
            height="28px"
          />
          <div class="nickname">
            {{ $Base64.decode(item.wechat_nickname) }}
          </div>
        </div>
      </div>
    </van-cell-group>

    <van-cell-group title="核销码" border v-if="dataList.join_status === 1">
      <van-cell style="text-align:center">
        <VueQr
          :correctLevel="3"
          :autoColor="false"
          :text="qrCodeUrl()"
          :size="128"
          :margin="0"
          :logoMargin="3"
          style="margin: 0px auto; display: block"
        />
      </van-cell>
    </van-cell-group>

    <van-tabbar>
      <van-button
        @click="onSubmit"
        :type="statusTxt.btnType"
        block
        :disabled="statusTxt.disabled"
      >
        <div>{{ statusTxt.btnTxt }}</div>
        <div
          class="font12"
          style="padding-top: 3px; color: rgba(255, 255, 255, 0.85)"
        >
          {{ statusTxt.statusTxt }}
        </div>
      </van-button>
    </van-tabbar>
    <FormList ref="formlist" :form="form" :form_id="dataList.form_id" />
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import qs from 'qs'
import Card from '@/components/card/index'
import FormList from './components/form'
import { Notify } from 'vant'
import VueQr from 'vue-qr'
import Cookies from 'js-cookie'
export default {
  components: {
    Card,
    FormList,
    VueQr
  },
  data () {
    return {
      dataList: {
        cover_img: '',
        join_list: []
      },
      goodsDetail: {
        sale_price: 0
      },
      sys_id: '',
      timerStatus: null,
      timerTxt: '',
      statusTxt: {
        btnType: 'info',
        disabled: true,
        btnTxt: '活动信息加载中'
      },
      form: {}

    }
  },
  mounted () {
    this.getDataList()
  },
  watch: {
    timerStatus () {
      this.getStatus()
    }
  },
  methods: {
    onSubmit (val = true) {
      let _this = this
      if (this.dataList.form_id && val) {
        this.$refs.formlist.show()
      } else {
        //正式提交
        this.$axios.post('/wxc/article/join', {
          article_id: this.dataList.article_id,
          relation_id: this.dataList.relation_id,
          data_list: this.form,
          channel: 1,
          address_id: 4
        }).then(res => {
          if (res.code === 200) {
            if (res.data.status === 0) {
              //如关联商品，需要在线支付
              wx.chooseWXPay({
                timestamp: res.data.payment.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: res.data.payment.nonceStr, // 支付签名随机串，不长于 32 位
                package: res.data.payment.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: res.data.payment.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: res.data.payment.paySign, // 支付签名
                success: function () {
                  // 支付成功后的回调函数
                  // _this.$router.push({ name: 'MyAct' })
                  _this.$refs.formlist.hide()
                  _this.dataList.join_status = 1
                  _this.dataList.join_sys_id = res.data.sys_id
                },

              })
            } else {
              this.dataList.join_status = 1
              this.sys_id = res.data.sys_id
            }

          } else {
            Notify({ type: 'danger', message: res.msg })
          }
        })
      }
    },
    qrCodeUrl () {
      return location.origin + '/#/my/sign?sys_id=' + this.dataList.join_sys_id
    },
    setWxShare (cover_img) {
      let _this = this
      let jsonData = {
        article_id: this.dataList.article_id,
        from_id: Number(Cookies.get('client_id')),
        relation_id: Number(this.dataList.relation_id)
      }
      let shareUrl = location.origin + `/#${this.$route.path}?` + qs.stringify(jsonData)

      wx.ready(function () {
        // wx.checkJsApi({
        //   jsApiList: ['chooseWXPay'],
        //   success: (res) => {
        //     console.log(res)
        //   },
        //   fail: (res) => {
        //     console.log(res)
        //   }
        // })

        //分享朋友圈
        wx.onMenuShareTimeline({
          title: _this.dataList.title,
          link: shareUrl,
          imgUrl: cover_img,
          success: function () {
            // 设置成功
            _this.$axios.post('/wxc/article/forward', {
              article_id: _this.dataList.article_id,
              relation_id: Number(_this.dataList.relation_id) || 0,
              channel: 2
            }).then(() => {
              alert('成功分享到朋友圈！')
            })
          }
        })

        //转发好友
        wx.onMenuShareAppMessage({
          title: _this.dataList.title, // 分享标题
          desc: _this.dataList.desc, // 分享描述
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: cover_img, // 分享图标
          success: function () {
            // 设置成功
            _this.$axios.post('/wxc/article/forward', {
              article_id: _this.dataList.article_id,
              relation_id: Number(_this.dataList.relation_id) || 0,
              channel: 1
            }).then(() => {
              alert('成功分享到微信好友！')
            })
          }
        })

      })

    },
    getDataList () {
      let para = { article_id: Number(this.$route.query.article_id) }
      if (this.$route.query.relation_id) {
        para.relation_id = Number(this.$route.query.relation_id)
      }
      para.join_count = 30

      this.$axios.post('/wxc/article/detail', para).then(res => {
        this.dataList = res.data
        this.sys_id = res.data.join_sys_id
        this.setWxShare(res.data.cover_img)

        if (res.data.goods_no) {
          this.$axios.post('/wxc/goods/detail', {
            goods_no: res.data.goods_no
          }).then(r => {
            this.goodsDetail = r.data
          })
        }

        this.getActStatus()

      })
    },

    isValidDate () {
      // let time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      let time = Date.parse(new Date())

      let diff = this.$moment(this.dataList.expired_date).format('x') - time

      if (diff <= 0) {
        clearInterval(this.timer)
        return false
      }

      var date = parseInt(diff / (24 * 60 * 60 * 1000));
      //获取倒计时结束的小时数
      var hour = parseInt(diff / (60 * 60 * 1000) - date * 24);
      hour = hour < 10 ? '0' + hour : hour;
      //获取倒计时结束的分钟数
      var minute = parseInt(diff / (60 * 1000) - date * 24 * 60 - hour * 60);
      minute = minute < 10 ? '0' + minute : minute;
      //获取倒计时结束的秒数
      var second = parseInt(diff / 1000 - date * 24 * 60 * 60 - hour * 60 * 60 - minute * 60);
      this.timerTxt = date + '天' + hour + '小时' + minute + '分钟' + second + '秒'
    },

    getActStatus () {
      let time = Date.parse(new Date())
      if (this.dataList.effected_date) {
        //设置了开始时间
        let effected_date = this.$moment(this.dataList.effected_date).format('x')
        if (time < effected_date) {
          //活动未开始
          this.timerStatus = 'not-started'
        } else {

          if (this.dataList.expired_date) {
            //设置了结束时间
            let expired_date = this.$moment(this.dataList.expired_date).format('x')
            if (time > expired_date) {
              //活动已结束
              this.timerStatus = 'ended'
            } else {
              //进行中
              this.timerStatus = 'doing'
              this.getTimer()
            }
          } else {
            //进行中
            this.timerStatus = 'doing'
          }

        }
      } else {
        //没有设置开始时间

        //如果设置了结束时间
        if (this.dataList.expired_date) {
          let expired_date = this.$moment(this.dataList.expired_date).format('x')
          if (time > expired_date) {
            //活动已结束
            this.timerStatus = 'ended'
          } else {
            //进行中
            this.timerStatus = 'doing'
            this.getTimer()
          }
        } else {
          this.timerStatus = 'doing'
        }
      }
    },

    getTimer () {
      this.timer = setInterval(() => {
        this.isValidDate()
      }, 1000)
    },

    getStatus () {

      if (this.timerStatus === 'not-started') {
        this.statusTxt.btnType = 'info'
        this.statusTxt.disabled = true
        this.statusTxt.btnTxt = '活动未开始'
        this.statusTxt.statusTxt = ''
      }

      if (this.timerStatus === 'ended') {
        this.statusTxt.btnType = 'default'
        this.statusTxt.disabled = true
        this.statusTxt.btnTxt = '活动已结束'
        this.statusTxt.statusTxt = ''
      }

      if (this.timerStatus === 'doing') {

        if (this.dataList.join_status === 0) {
          this.statusTxt.btnType = 'info'
          this.statusTxt.disabled = false
          this.statusTxt.btnTxt = '立即参与活动'
        }

        if (this.dataList.join_status === 1) {
          this.statusTxt.btnType = 'info'
          this.statusTxt.disabled = true
          this.statusTxt.btnTxt = '已经参加活动'
          this.statusTxt.statusTxt = '未核销'
        }

        if (this.dataList.join_status === 2) {
          this.statusTxt.btnType = 'info'
          this.statusTxt.disabled = true
          this.statusTxt.btnTxt = '已经参加活动'
          this.statusTxt.statusTxt = '已核销'
        }

      }

    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  background: #f9f9f9;
  min-height: 100vh;
  padding-bottom: 100px;
}
.cover-img-box {
  position: relative;
  ::v-deep .van-image {
    display: block;
  }
  .cover-img {
    width: 100%;
    z-index: 1;
    min-height: 24px;
  }
  .join-count {
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: #0c2481;
    color: #fff;
    line-height: 2;
    padding: 0 10px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    .num {
      color: #f6fa19;
    }
  }
}

.act-info {
  padding: 10px 16px;
  background: #fff;
  .title {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 8px;
  }
  .desc {
    line-height: 1.6;
  }
}

.gs {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  .gs-item {
    width: 25%;
    padding: 3px 0;
    color: rgba(0, 0, 0, 0.55);
    .icon {
      margin-right: 3px;
      color: #0c2481;
    }
  }
}

.rich_media_content {
  padding: 5px 16px;
  /deep/ p {
    padding: 5px 0;
  }
  table {
    max-width: 100%;
  }
}

.title-h2 {
  font-weight: bolder;
  position: relative;
  ::v-deep .van-cell__value {
    padding-left: 8px;
  }
  &::before {
    content: ' ';
    position: absolute;
    width: 2px;
    height: 12px;
    background: #0c2481;
    top: 50%;
    transform: translateY(-50%);
  }
}

.price {
  ::v-deep .van-cell__title {
    color: #ee0a24;
    font-weight: bolder;
    flex: unset;
  }
}
.timer-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ico {
    margin-right: 5px;
  }
  .timer {
    margin-left: 5px;
    color: #ee0a24;
  }
}
.no-data {
  width: 100%;
}
.join-list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 16px 0;

  .list-item {
    width: 10%;
    text-align: center;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .nickname {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow: hidden;
  }
}
</style>
